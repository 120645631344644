"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var defaultCacheResponseForSeconds = 2 * 60;
var defaultMaxSize = 100;
var RequestWithCache = /** @class */function () {
  function RequestWithCache() {
    this.responseCache = new Map();
    this.responsePromiseCache = new Map();
  }
  RequestWithCache.prototype.clearCache = function () {
    this.responseCache = new Map();
    this.responsePromiseCache = new Map();
  };
  // Todo: should probably be passed a callback instead, or an apiCall instance. Types are messy this way
  RequestWithCache.prototype.perform = function (requestContext, requestFunction, requestFunctionArguments, cacheOptions) {
    return __awaiter(this, void 0, void 0, function () {
      var _a, cacheResponseForSeconds, _b, maxSize, isCacheDisabled, requestFunctionArgumentsJSON, cacheEntry, now, isEntryValid, cachePromiseEntry, isEntryValid, responsePromise, response, isCacheOverMaxSize, oldestEntry, isResponsePromiseCacheOverMaxSize, oldestEntry;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            _a = cacheOptions.cacheResponseForSeconds, cacheResponseForSeconds = _a === void 0 ? defaultCacheResponseForSeconds : _a, _b = cacheOptions.maxSize, maxSize = _b === void 0 ? defaultMaxSize : _b;
            isCacheDisabled = cacheResponseForSeconds <= 0 || maxSize <= 0;
            if (isCacheDisabled) {
              return [2 /*return*/, requestFunction.call.apply(requestFunction, __spreadArray([requestContext], requestFunctionArguments, false))];
            }
            requestFunctionArgumentsJSON = JSON.stringify(requestFunctionArguments);
            cacheEntry = this.responseCache.get(requestFunctionArgumentsJSON);
            now = Date.now();
            if (cacheEntry) {
              isEntryValid = now - cacheEntry.requestTimestamp < cacheResponseForSeconds * 1000;
              if (isEntryValid) {
                this.responseCache.delete(requestFunctionArgumentsJSON);
                this.responseCache.set(requestFunctionArgumentsJSON, cacheEntry);
                return [2 /*return*/, Promise.resolve(cacheEntry.response)];
              } else {
                this.responseCache.delete(requestFunctionArgumentsJSON);
              }
            }
            cachePromiseEntry = this.responsePromiseCache.get(requestFunctionArgumentsJSON);
            if (cachePromiseEntry) {
              isEntryValid = now - cachePromiseEntry.requestTimestamp < cacheResponseForSeconds * 1000;
              if (isEntryValid) {
                this.responsePromiseCache.delete(requestFunctionArgumentsJSON);
                this.responsePromiseCache.set(requestFunctionArgumentsJSON, cachePromiseEntry);
                return [2 /*return*/, cachePromiseEntry.responsePromise];
              } else {
                this.responsePromiseCache.delete(requestFunctionArgumentsJSON);
              }
            }
            responsePromise = requestFunction.call.apply(requestFunction, __spreadArray([requestContext], requestFunctionArguments, false));
            this.responsePromiseCache.set(requestFunctionArgumentsJSON, {
              requestTimestamp: now,
              responsePromise: responsePromise
            });
            return [4 /*yield*/, responsePromise];
          case 1:
            response = _c.sent();
            this.responseCache.set(requestFunctionArgumentsJSON, {
              requestTimestamp: now,
              response: response
            });
            isCacheOverMaxSize = this.responseCache.size > maxSize;
            if (isCacheOverMaxSize) {
              oldestEntry = this.responseCache.keys().next().value;
              this.responseCache.delete(oldestEntry);
            }
            isResponsePromiseCacheOverMaxSize = this.responsePromiseCache.size > maxSize;
            if (isResponsePromiseCacheOverMaxSize) {
              oldestEntry = this.responsePromiseCache.keys().next().value;
              this.responsePromiseCache.delete(oldestEntry);
            }
            return [2 /*return*/, response];
        }
      });
    });
  };
  return RequestWithCache;
}();
exports.default = RequestWithCache;
