import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-basic-modal',
  templateUrl: './basic-modal.component.html',
  styleUrls: ['./basic-modal.component.scss'],
})
export class BasicModalComponent implements OnInit {
  inputControl = new UntypedFormControl('', Validators.required);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      cancelText: string;
      confirmText: string;
      message: string;
      title: string;
      withInputConfirm: boolean;
      changes: { property: string, newValue: any, oldValue: any }[];
    },
    private mdDialogRef: MatDialogRef<BasicModalComponent>
  ) {}

  ngOnInit() {}

  public cancel() {
    this.close(false);
  }

  public close(value: any) {
    this.mdDialogRef.close(value);
  }

  public confirm() {
    if (this.inputControl.value !== 'carrot' && this.data.withInputConfirm) {
      this.inputControl.setErrors({ required: true });
      return;
    }
    this.close(true);
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this.close(false);
  }
}
