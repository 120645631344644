import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { JsonEditorOptions } from "ang-jsoneditor";

@Component({
  selector: "app-json-edit-modal",
  templateUrl: "./json-edit-modal.component.html",
  styleUrls: ["./json-edit-modal.component.scss"],
})
export class JSONEditModalComponent implements OnInit {
  loaderShown: boolean = false;
  jsonOptions: JsonEditorOptions;
  jsonToEdit: JSON;
  newJSON: JSON;
  saveButtonLoading: boolean = false;
  saveButtonDisabled: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      cancelText: string,
      confirmText: string,
      message: string,
      title: string,
      jsonToEdit: JSON,
  }, private mdDialogRef: MatDialogRef<JSONEditModalComponent>){}

  async ngOnInit() {
    this.loaderShown = true;
    this.jsonOptions = new JsonEditorOptions();
    this.jsonOptions.modes = ['code', 'text', 'tree', 'view']; // set all allowed modes
    this.jsonOptions.mainMenuBar = true;
    this.jsonOptions.navigationBar = true;
    this.jsonToEdit = this.data.jsonToEdit;
    this.newJSON = JSON.parse(JSON.stringify(this.jsonToEdit));
    this.loaderShown = false;
  }

  jsonChange(event) {
    if(event.type === "change"){
      return;
    }
    this.newJSON = event;
  }

  public cancel() {
    this.close(false);
  }

  public async confirm() {
    this.saveButtonDisabled = true;
    this.saveButtonLoading = true;
    this.saveButtonDisabled = false;
    this.saveButtonLoading = false;

    this.close(this.newJSON);
  }
  
  @HostListener("keydown.esc") 
  public onEsc() {
    this.close(false);
  }

  public close(value: any) {
    this.mdDialogRef.close(value);
  }
}
