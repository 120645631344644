import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SsoClientPublicConfig } from '../model/sso-client';
import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentClientService {
    
    clientId: string;
    routePrefix: string;
    initialRoute: string;
    publicClientConfig$: Observable<SsoClientPublicConfig>;
    constructor(
        private clientService: ClientService,
        private location: Location
    ){
    }

    async init(){
      if (!window.location.pathname.startsWith("/app/")) {
        // reloads the entire app
        window.location.href = "/app/" +environment.clientId + window.location.pathname;
      }

      let clientId = this.getClientId();
      let response: any;
      try {
        response = await firstValueFrom(this.clientService.getClientPublicConfig(clientId));
        // here response is successfull, meaning client id is valid
      } catch (error) {
        // here response is unsuccessfull, meaning client id is invalid, thus substituting with the hardcoded one
        clientId = environment.clientId;
        // update URL with real /app/appId without causing reload
        this.location.replaceState("/app/" + clientId);
        response = await firstValueFrom(this.clientService.getClientPublicConfig(clientId));
      };

      this.clientId = clientId
      this.routePrefix = "/app/" + this.clientId;
      this.initialRoute = this.getInitialRoute(window.location.pathname + window.location.search);
      this.publicClientConfig$ = of(response.data);
    }

    clearInitialRoute() {
      this.initialRoute = "";
    }

    private getClientId():string {
      let clientId = environment.clientId;
      if (window.location.pathname.startsWith("/app/")) {
        const segments = window.location.pathname.split('/');
        clientId = segments[2];
      }

      return clientId;
    }

    private getInitialRoute(pathname:string) :string {
      return pathname != this.routePrefix &&
        pathname != this.routePrefix + "/" ?
        pathname: "";
    }
}
