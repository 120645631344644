import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { collection, collectionData, doc, Firestore, docData } from '@angular/fire/firestore';
import { firstValueFrom, Observable } from 'rxjs';
import { SsoApiResponse } from '../model/shared';
import { SsoClient, SsoClientPublicConfig } from '../model/sso-client';
import { formatApiUrl } from './utilities';
import { FirestoreService } from '../shared/services/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  
  constructor(
    private http: HttpClient,
    private firestore: FirestoreService,
  ) { }

  async getClientById(id: string) {
    const client = await this.firestore.getDocumentData<SsoClient>('clients', id);
    return client;
  }

  async getClients() {
    const clients = await this.firestore.getCollectionData<SsoClient[]>('clients');
    return clients;
  }

  getClientPublicConfig(clientId: string) : Observable<SsoApiResponse<SsoClientPublicConfig>> {
    return this.http.get<SsoApiResponse<SsoClientPublicConfig>>(formatApiUrl('/api/v1/config/client/' + clientId));
  }
}
