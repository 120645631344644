import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom, from, map, Observable, of, switchMap } from 'rxjs';
import { SsoUser } from '../model/sso-user';
import { CurrentClientService } from './current-client.service';
import { UserAdminService } from './user-admin.service';
import { Auth, authState, signInWithCustomToken, signOut } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

    // firebase authentication servier
    private fireAuth: Auth = inject(Auth);
    // firebase authentication state of the user
    authState$ = authState(this.fireAuth);

    isLoggedIn$: Observable<boolean>;
    isLoggedOut$: Observable<boolean>;
    user$: Observable<SsoUser>;
    isSsoAdmin$: Observable<boolean>;

    constructor(
        private router: Router,
        private userAdminService: UserAdminService,
        public currentClientService: CurrentClientService
        ){

            this.isLoggedIn$ = this.authState$
            .pipe(
                map(user => !!user)
            );

        this.isLoggedOut$ = this.isLoggedIn$
            .pipe(
                map(loggedIn => !loggedIn)
            );

        this.user$ = this.authState$
            .pipe(
                switchMap(user => user ? from(user.getIdTokenResult()) : of(null)),
                map(idToken => {
                    if (!idToken) return null;
                    const user = {
                        id: idToken?.claims['user_id'],
                        email: idToken?.claims['email'],
                        firstname: idToken?.claims['firstname'],
                        lastname: idToken?.claims['lastname'],
                        rolesByClientId: idToken?.claims['rolesByClientId'],
                        isSsoAdmin: idToken?.claims['isSsoAdmin'] ?? false
                    } as SsoUser

                    return user;
                })
            );
        
        this.isSsoAdmin$ = this.user$
            .pipe(
                map(user => user && user.isSsoAdmin)
            );
    }

    async reLogin(redirect: boolean){
        const resp = await firstValueFrom(this.userAdminService.checkSession());
        if (resp.data && resp.data.session) {
            const loginToken = resp.data.loginToken;
            await signInWithCustomToken(this.fireAuth, loginToken);
            
            if(redirect) {
                await this.router.navigateByUrl(this.currentClientService.routePrefix + "/dispatch");
            }
        }
    }

    async refreshIdToken(force: boolean = true) {
        const user = this.fireAuth.currentUser;
        await user.getIdTokenResult(force);
    }

    async logout(){
        await firstValueFrom(this.userAdminService.deleteSessionCookie());
        await signOut(this.fireAuth);
        await this.router.navigateByUrl(this.currentClientService.routePrefix + "/login");
    }
}
