import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { lastValueFrom } from "rxjs";
import { CurrentClientService } from "src/app/services/current-client.service";
import { Component, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class TabTitleService {

  private client: any;
  private clientName = '';
  private faviconUrl = '';

  constructor(
    private titleService: Title,
    private currentClientService: CurrentClientService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  async setTabTitle(tabTitle: string) {
    this.client = await lastValueFrom(this.currentClientService.publicClientConfig$);
    this.clientName = this.client.name;
    this.faviconUrl = this.client.faviconImageURL;
    if (this.clientName) {
      this.titleService.setTitle(`${tabTitle} - ${this.clientName}`);
    } else {
      this.titleService.setTitle(`${tabTitle} - Carrot ID`);
    };
    if (this.faviconUrl.length > 0) {
      this._document.getElementById('appFavicon').setAttribute('href', this.faviconUrl);
    }
  }
}