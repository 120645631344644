export const environment = {
  firebase: {
    apiKey: "AIzaSyAfYa3kSBoBY-MhMaQ8gGQ3iPAD0vM9aAY",
    authDomain: "update-carrot-sso-332b2.firebaseapp.com",
    projectId: "update-carrot-sso-332b2",
    storageBucket: "update-carrot-sso-332b2.appspot.com",
    messagingSenderId: "736354108985",
    appId: "1:736354108985:web:89145ba66bf0188b8f2fe8",
    measurementId: "G-KZDHJCXDVR"
  },
  production: true,
  name: "UPDATE",
  useEmulators: false,
  apiBaseUrl: "",
  clientId: "y93GGqoGztk4nIITWhBD"
};
