import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  map,
} from 'rxjs/operators';
import {
  collectionData,
  doc,
  docData,
  Firestore,
  collection,
  QueryConstraint,
  query,
  deleteDoc,
  serverTimestamp,
  updateDoc,
  setDoc,
  docSnapshots,
  getDocs,
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'any',
})
export class FirestoreService {
  private firestore: Firestore = inject(Firestore);

  constructor() {}

  /**
   * Get firestore object mapped to provided type
   * @param path 
   * @param id 
   * @returns document of type provided
   */
  async getDocumentData<T>(path: string, id: string) {
    const ref = doc(this.firestore, path, id);
    const data = await firstValueFrom(docData(ref, { idField: 'id' }));
    return data as T;
  }

  /**
   * Get firestore array of objects mapped to provided type
   * @param path 
   * @param search 
   * @returns collection mapped to given type
   */
  async getCollectionData<T>(path: string, search: QueryConstraint[] = []) {
    const ref = collection(this.firestore, path);
    const q = query(ref, ...search);
    const data = await firstValueFrom(collectionData(q, { idField: 'id' }));
    return data as T;
  }

  /**
   * Get firestore array of collection snapshots (primary use is for pagination)
   * @param path 
   * @param search 
   * @returns array of collection snapshots
   */
  async getCollectionSnapshots(path: string, search: QueryConstraint[] = []) {
    const ref = collection(this.firestore, path);
    const q = query(ref, ...search);
    const data = await getDocs(q);
    return data;
  }

  /**
   * Delete firestore document
   * @param path 
   * @param id 
   * @returns void
   */
  async deleteDocument(path: string, id: string) {
    const ref = doc(this.firestore, path, id);
    return deleteDoc(ref);
  }

  /**
   * Update firestore object
   * @param path 
   * @param id 
   * @param data 
   * @returns updated document
   */
  async updateDocument<T>(path: string, id: string, data: any) {
    const ref = doc(this.firestore, path, id);
    await updateDoc(ref, { ...data, updatedAt: serverTimestamp() });
    const updated = await firstValueFrom(docData(ref, { idField: 'id' }));
    return updated as T;
  }

  /**
   * Create firestore object
   * @param path 
   * @param data 
   * @returns created document of type provided
   */
  async createDocument<T>(path: string, data: any) {
    const ref = collection(this.firestore, path);
    const id = this.createDocumentId(path);
    const create = {
      ...data,
      id,
      updatedAt: serverTimestamp(),
      createdAt: serverTimestamp(),
    };
    await setDoc(doc(ref, id), create);
    return create as T;
  }

  /**
   * Create or update firestore object
   * @param path 
   * @param id 
   * @param data 
   * @returns created document of type provided
   */
  async upsertDocument<T>(path: string, id: string, data: any) {
    const docRef = doc(this.firestore, path, id);
    const exist = await firstValueFrom(docData(docRef));
    if (exist) {
      await updateDoc(docRef, { ...data, updatedAt: serverTimestamp() });
    } else {
      await setDoc(docRef, {
        ...data,
        id,
        updatedAt: serverTimestamp(),
        createdAt: serverTimestamp(),
      });
    }
    const document = await firstValueFrom(docData(docRef, { idField: 'id' }));
    return document as T;
  }

  /**
   * Set a firestore object with provided id
   * @param path 
   * @param id 
   * @param data 
   * @returns created document of type provided
   */
  async setDocument<T>(path: string, id: string, data: any) {
    const ref = doc(this.firestore, path, id);
    await setDoc(ref, data);
    const updated = await firstValueFrom(docData(ref, { idField: 'id' }));
    return updated as T;
  }

  /**
   * Create firestore id based on collection path
   * @param path 
   * @returns string
   */
  createDocumentId(path: string) {
    const ref = collection(this.firestore, path);
    const { id } = doc(ref);
    return id;
  }

  /**
   * Get doc change listener
   * @param path 
   * @param id 
   * @returns firebase object mapped to type listener
   */
  getDocSnapshots<T>(path: string, id: string) {
    const ref = doc(this.firestore, path, id);
    return docSnapshots(ref).pipe(map(snap => snap.data() as T));
  }

  /** 
   * Get firestore instance
  */
  getFirestore() {
    return this.firestore;
  }

  /**
   * Return firebase server time
   * @returns firebase server time
   */
  getServerTimestamp() {
    return serverTimestamp();
  }
}